<template>
  <div>
    <template v-for="group in jsonData">
      <div class="container">
        <div class="container-header">
          <span>{{ group.groupName }}</span>
<!--          <span v-if="group.groupId === 3" class="show-all" @click="()=>showAll=!showAll">显示全部</span>-->
        </div>
        <div class="container-body">
          <template v-for="item in group.items.filter((i) => {
            return !(i.location && i.location.length > 0 && location.indexOf(i.location) < 0 && !showAll)
          })">
            <div class="item-block">
              <a :href="item.url" target="_blank">
                <div class="item-top">
                  <div class="item-logo">
                    <img :src="item.img">
                  </div>
                  <div class="item-body">
                    <span class="title">{{ item.title }}</span>
                    <span class="notice">{{ item.notice }}</span>
                  </div>
                </div>
              </a>
              <div class="item-bottom">
                <a :href="item.helpUrl||''" target="_blank">
                  <div>帮助文档</div>
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <div v-if="false">
      <div class="container">
        <div class="container-header">内部系统导航</div>
        <div class="container-body">
          <div class="item-block">
            <a href="https://gitlab.com/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/gitlab.png"></div>
                <div class="item-body">
                  <span class="title">Gitlab系统</span>
                  <span class="notice">Gitlab内部代码仓库</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="https://docs.gitlab.com/ee/README.html" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://www.seeease.com/wiki/plugins/servlet/forward.html"
               target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/wiki.png"></div>
                <div class="item-body">
                  <span class="title">WIKI系统</span>
                  <span class="notice">文档系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <div>帮助文档</div>
            </div>
          </div>
          <div class="item-block">
            <a href="https://www.seeease.com/jira/plugins/servlet/forward.html"
               target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/jira.jpeg"></div>
                <div class="item-body">
                  <span class="title">jira系统</span>
                  <span class="notice">项目管理系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <div>帮助文档</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="container-header">运维导航</div>
        <div class="container-body">


          <div class="item-block">
            <a href="http://pre.seeease.com:7889" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/geoaccess.png"></div>
                <div class="item-body">
                  <span class="title">渠道日志平台</span>
                  <span class="notice">日志统计</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>

          <!--    <div class="item-block">-->
          <!--      <a href="https://spug.qbangmang.com/login" target="_blank" >-->
          <!--      <div class="item-top" >-->
          <!--        <div class="item-logo"><img src="../assets/img/spug.png"></div>-->
          <!--        <div class="item-body">-->
          <!--          <span class="title">开源运维平台</span>-->
          <!--          <span class="notice">Spug开源运维平台</span>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--    </a>-->
          <!--      <div class="item-bottom">-->
          <!--        <a href="https://github.com/openspug/spug" target="_blank">-->
          <!--        <div>源码地址</div>-->
          <!--      </a>-->
          <!--      </div>-->
          <!--    </div>-->

          <!--      <div class="item-block">-->
          <!--      <a href="https://github.com/openspug/spug" target="_blank" >-->
          <!--      <div class="item-top" >-->
          <!--        <div class="item-logo"><img src="../assets/img/github.png"></div>-->
          <!--        <div class="item-body">-->
          <!--          <span class="title">开源运维平台</span>-->
          <!--          <span class="notice">Spug运维平台源码</span>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--    </a>-->
          <!--      <div class="item-bottom">-->
          <!--        <a href="https://spug.qbangmang.com/login" target="_blank">-->
          <!--        <div>演示地址</div>-->
          <!--      </a>-->
          <!--      </div>-->
          <!--    </div>-->


          <!--    <div class="item-block">-->
          <!--      <a href="https://grafana.com/" target="_blank">-->
          <!--      <div class="item-top">-->
          <!--        <div class="item-logo"><img src="../assets/img/grafana.png"></div>-->
          <!--        <div class="item-body">-->
          <!--          <span class="title">监控系统</span>-->
          <!--          <span class="notice">Grafana 监控平台</span>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--    </a>-->
          <!--        <div class="item-bottom">-->
          <!--          <div>帮助文档</div>-->
          <!--        </div>-->
          <!--    </div>-->

          <!--    <div class="item-block">-->
          <!--      <a href="https://www.zabbix.com/" target="_blank">-->
          <!--      <div class="item-top">-->
          <!--        <div class="item-logo"><img src="../assets/img/zabbix.png"></div>-->
          <!--        <div class="item-body">-->
          <!--          <span class="title">监控报警</span>-->
          <!--          <span class="notice">Zabbix监控报警平台</span>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--    </a>-->
          <!--      <div class="item-bottom">-->
          <!--        <div>帮助文档</div>-->
          <!--      </div>-->
          <!--    </div>-->

          <div class="item-block">
            <a href="https://www.seeease.com/jenkins/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/jenkins.png"></div>
                <div class="item-body">
                  <span class="title">发布系统</span>
                  <span class="notice">Jenkins发布系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <div>帮助文档</div>
            </div>
          </div>

          <!--    <div class="item-block">-->
          <!--      <a href="http://www.jumpserver.org/" target="_blank">-->
          <!--      <div class="item-top" >-->
          <!--        <div class="item-logo"><img src="../assets/img/jumpserver.png"></div>-->
          <!--        <div class="item-body">-->
          <!--          <span class="title">跳板机系统</span>-->
          <!--          <span class="notice">Jumpserver 跳板机系统</span>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--    </a>-->
          <!--      <div class="item-bottom">-->
          <!--        <div>帮助文档</div>-->
          <!--      </div>-->
          <!--    </div>-->

          <!--    <div class="item-block">-->
          <!--      <a href="http://yearning.io/" target="_blank">-->
          <!--      <div class="item-top" >-->
          <!--        <div class="item-logo"><img src="../assets/img/sql2.png"></div>-->
          <!--        <div class="item-body">-->
          <!--          <span class="title">数据库系统</span>-->
          <!--          <span class="notice">数据库审核平台</span>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--    </a>-->
          <!--      <div class="item-bottom">-->
          <!--        <div>帮助文档</div>-->
          <!--      </div>-->
        </div>

        <!-- <div class="item-block">
          <div class="item-top">
            <div class="item-logo"><img src="../assets/img/sky.png"></div>
            <div class="item-body">
              <span class="title">链路系统</span>
              <span class="notice">Skywalking链路追踪系统</span>
            </div>
          </div>

          <div class="item-bottom">
            <div onclick="window.open('http://xxx/')">测试环境</div>
            <div class="divider"></div>
            <div onclick="window.open('http://xxx/')">正式环境</div>
            <div class="divider"></div>
            <div>帮助文档</div>
          </div>
        </div>-->

      </div>
      <div class="container">
        <div class="container-header">内部系统导航</div>
        <div class="container-body">
          <div class="item-block">
            <a href="https://feilun.seeease.com/erp/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/erp.png"></div>
                <div class="item-body">
                  <span class="title">稀蜴ERP</span>
                  <span class="notice">ERP管理系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/pj/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/配件管理.png"></div>
                <div class="item-body">
                  <span class="title">配件管理系统</span>
                  <span class="notice">配件管理系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9110/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/小蜴.png"></div>
                <div class="item-body">
                  <span class="title">小蜴 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9111/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/计时.png"></div>
                <div class="item-body">
                  <span class="title">计时 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9112/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/绍兴百盛.png"></div>
                <div class="item-body">
                  <span class="title">绍兴百盛 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9113/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/融易.png"></div>
                <div class="item-body">
                  <span class="title">融易 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9114/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/沈阳自有.png"></div>
                <div class="item-body">
                  <span class="title">沈阳自有 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9115/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/稀蜴盛时.png"></div>
                <div class="item-body">
                  <span class="title">稀蜴盛时 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9116/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/诸暨丹顿.png"></div>
                <div class="item-body">
                  <span class="title">诸暨丹顿 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9117/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/温州吾悦.png"></div>
                <div class="item-body">
                  <span class="title">温州吾悦 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9118/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/南宁百盛.png"></div>
                <div class="item-body">
                  <span class="title">南宁百盛 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9119/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/沈阳稀蜴.png"></div>
                <div class="item-body">
                  <span class="title">沈阳稀蜴 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9120/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/杭州星光.png"></div>
                <div class="item-body">
                  <span class="title">杭州星光 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9121/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/佰时力.png"></div>
                <div class="item-body">
                  <span class="title">佰时力 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>
          <div class="item-block">
            <a href="https://feilun.seeease.com/jxc/9122/" target="_blank">
              <div class="item-top">
                <div class="item-logo"><img src="../assets/img/南宁万象.png"></div>
                <div class="item-body">
                  <span class="title">南宁二店 进销存</span>
                  <span class="notice">进销存系统</span>
                </div>
              </div>
            </a>
            <div class="item-bottom">
              <a href="" target="_blank">
                <div>帮助文档</div>
              </a>
            </div>
          </div>

          <!--		<div class="item-block">-->
          <!--			<a href="https://www.seeease.com/jenkins/" target="_blank">-->
          <!--				<div class="item-top">-->
          <!--					<div class="item-logo"><img src="../assets/img/jenkins.png"></div>-->
          <!--					<div class="item-body">-->
          <!--						<span class="title">发布系统</span>-->
          <!--						<span class="notice">Jenkins发布系统</span>-->
          <!--					</div>-->
          <!--				</div>-->
          <!--			</a>-->
          <!--			<div class="item-bottom">-->
          <!--				<div>帮助文档</div>-->
          <!--			</div>-->
          <!--		</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import json from '@/assets/nav_data.json'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data() {
    return {
      jsonData: [],
      location: '',
      showAll:false,
    }
  },
  created() {
    this.location = returnCitySN.cname
    // alert("IP地址是：" + returnCitySN['cip'] + "    " + "城市名字：" + returnCitySN['cname']);
  },
  mounted() {
    this.jsonData = JSON.parse(JSON.stringify(json))
    this.jsonData.forEach((g) => {
      g.items.forEach((i) => {
        i.img = require('@/assets' + i.img)
      })
    })
    console.log('this.jsonData', this.jsonData)
    console.log('this.location', this.location)
  }
}
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F7F7F7;
}

.container {
  background-color: #fff;

}

.container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  padding-left: 10px;

}

.container-body {
  display: flex;
  border-top: 1px solid #dfdfdf;
  padding: 20px 10px 0 10px;
  /*justify-content: space-between;*/
  flex-direction: row;
  flex-wrap: wrap;
}

.container div {
  display: flex;
}

.item-block {
  border: 1px solid #dfdfdf;
  flex-direction: column;
  height: 160px;
  width: 24%;
  margin-bottom: 20px;
  margin-right: 10px;

}

a:link { /* 超链接正常状态下的样式 */
  color: #000000; /* 黑色 */
  text-decoration: none; /* 无下划线 */
}

a:visited { /* 访问过的超链接 */
  color: #000000; /* 黑色 */
  text-decoration: none; /* 无下划线 */
}


.item-top {
  height: 80px;
  padding: 20px;
}

.item-logo {
  width: 60px;
  height: 60px;
  margin-right: 20px;

}

.item-logo img {
  width: 100%;
  height: 100%;

}

.item-body {
  flex-direction: column;
  text-align: start;
}

.item-bottom {
  height: 40px;
  justify-content: space-around;
  align-items: center;
  color: #888888;
  background-color: #F7F7F7;
  font-size: 13px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
}

.notice {
  font-size: 12px;
  color: #888;
}

.divider {
  height: 20px;
  width: 1px;
  background-color: #dfdfdf;
}

.show-all{
  cursor: pointer;
}

.show-all:hover{
  color: #666;
}

</style>
